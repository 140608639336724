<template>
  <div
    class="justify-center w-full h-full d-flex align-center login-hero"
    style="background: white"
  >
    <v-img
      style="z-index: 3"
      alt="Company Logo"
      :class="{'login-logo':$vuetify.breakpoint.mdAndUp, 'login-logo-mobile': $vuetify.breakpoint.smAndDown}"
      :src="require('@/../public/img/logo.png')"
      width="140"
      contain
    />

    <v-card
      style="z-index: 2"
      class="pa-4"
      elevation="0"
    >
      <v-card-text class="d-flex flex-column align-center registration-container content">
        <div class="mt-4 text-center">
          <h1 class="mb-4 display-1 black--text font-weight-black">
            {{ title }}
          </h1>
          <p class="mb-8 text-body-2 black--text">
            {{ $t('signup.subtitle') }}
          </p>
        </div>
        <div class="mt-4">
          <v-form
            ref="signupForm"
            @submit="signup"
          >
            <v-text-field
              v-model.trim="firstname"
              :label="$t('signup.labels.firstname')"
              outlined
              hide-details="auto"
              class="mb-4"
              prepend-inner-icon="mdi-account-outline"
              :rules="[rules.required]"
              required
            />

            <v-text-field
              v-model.trim="lastname"
              :label="$t('signup.labels.lastname')"
              outlined
              hide-details="auto"
              class="mb-4"
              prepend-inner-icon="mdi-account-outline"
              :rules="[rules.required]"
              required
            />

            <v-text-field
              v-model.trim="email"
              :label="$t('signup.labels.email')"
              type="email"
              outlined
              hide-details="auto"
              class="mb-4"
              prepend-inner-icon="mdi-email-outline"
              :rules="[rules.email, rules.required]"
              required
            />

            <v-text-field
              v-model="password"
              :label="$t('signup.labels.password')"
              :type="showPassword ? 'text' : 'password'"
              hide-details="auto"
              outlined
              prepend-inner-icon="mdi-lock-outline"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              class="mb-4"
              :rules="[rules.required]"
              required
              @click:append="showPassword = !showPassword"
            />

            <v-checkbox
              v-model="checkbox"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <div class="text-body-2">
                  {{ $t('signup.terms-texts.0') }}
                  <IubendaDocumentLink
                    v-if="useIubenda"
                    type="terms"
                    @click.stop
                  />
                  <v-tooltip
                    v-else
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="publicTOCandLicenceUrl"
                        @click.stop
                        v-on="on"
                      >{{ $t('signup.terms-texts.1') }}</a>
                    </template>
                    {{ $t('signup.terms-tooltip') }}
                  </v-tooltip>
                  {{ $t('signup.terms-texts.2') }}
                  <IubendaDocumentLink
                    v-if="useIubenda"
                    type="privacyPolicy"
                    @click.stop
                  />
                  <v-tooltip
                    v-else
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="publicDataPrivacyUrl"
                        @click.stop
                        v-on="on"
                      >{{ $t('signup.terms-texts.3') }}</a>
                    </template>
                    {{ $t('signup.terms-tooltip') }}
                  </v-tooltip>
                  {{ $t('signup.terms-texts.4') }}
                </div>
              </template>
            </v-checkbox>

            <div
              class="px-0 d-flex flex-column"
            >
              <v-expand-transition>
                <v-alert
                  v-if="error"
                  type="error"
                  dense
                  tile
                  text
                  class="my-4"
                >
                  <span v-html="$t(getErrorMessage(error))" />
                </v-alert>
              </v-expand-transition>
              <loading-button
                color="primary"
                type="submit"
                class="mb-5"
                :loading="submitting"
              >
                {{ $t('buttons.signup.try-free') }}
              </loading-button>
            </div>
          </v-form>
        </div>
        <div class="mt-4 text-center">
          <span>{{ $t('signup.account-exists') }}</span>
          <a
            href="/auth/login"
            class="ml-2 primary--text font-weight-bold hover"
          >{{ $t('login.form.submit') }}</a>
        </div>
      </v-card-text>
    </v-card>
    <RemaxInfoDialog
      :is-de-remax-customer="isDeRemaxCustomer"
      @accept="email = ''"
    />
  </div>
</template>

<script>
import { iubenda } from '@/lib/features'
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import SIGN_UP_USER from '@/queries/SignUpUser.gql'
import { isValidPassword } from '@/lib/password'
import { email, required } from '@/lib/validation'
import RemaxInfoDialog from './RemaxInfoDialog.vue'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink'

export default {
  components: {
    LoadingButton: () => import('@/components/loading-button'),
    RemaxInfoDialog,
    IubendaDocumentLink
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      submitting: false,
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      checkbox: false,
      error: null,
      showPassword: false,
      useIubenda: iubenda?.isActive
    }
  },
  computed: {
    title () {
      return this.$route.params.token ? this.$t('signup.title-free') : this.$t('signup.title-free-trial')
    },
    formReady () {
      return (this.email.length && this.password.length && this.firstname.length && this.lastname.length)
    },
    isDeRemaxCustomer () {
      return Boolean(this.email.match(/@remax.*\.de$/i))
    },
    errorMessages () {
      return {
        emptyFields: 'alerts.signup.empty-fields',
        invalidToken: 'alerts.signup.invalid-token',
        invalidAgreement: 'alerts.signup.invalid-agreement',
        invalidPassword: 'alerts.signup.invalid-password',
        userExists: 'alerts.signup.user-exists',
        unknown: 'alerts.signup.unknown'
      }
    }
  },
  created () {
    this.rules = { email, required }
  },
  apollo: {
    $client: 'public'
  },
  methods: {
    async signup (event) {
      event.preventDefault()
      this.error = null
      const isValid = await this.$refs.signupForm.validate()

      if (isValid && this.formReady) {
        if (!isValidPassword(this.password)) {
          this.error = 'invalidPassword'
          return
        }

        this.submitting = true

        try {
          const { data } = await this.$apollo.mutate({
            mutation: SIGN_UP_USER,
            variables: {
              input: {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                password: this.password,
                locale: this.$i18n.locale,
                ...(this.$route.params.token && { token: this.$route.params.token })
              }
            }
          })

          const { status, errorCode } = data.signUpUser

          if (status === 'ERROR') {
            this.error = errorCode
            // keep spinning button till user is redirected. Only stop spinning on error.
            this.submitting = false
          } else {
            await this.$auth.login(this.email, this.password)
          }
        } catch (error) {
          this.error = 'unknwon'
          this.submitting = false
        }
      } else if (!this.checkbox) {
        this.error = 'invalidAgreement'
      } else {
        this.error = 'emptyFields'
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code] || this.errorMessages.unknown
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 450px;
}

.login-hero{
  position: relative;
  height: 100%;
}

.login-logo{
  position: absolute;
  top:0;
  left:0;
  margin-top:30px;
  margin-left:30px;
  z-index: 2;
}

.login-logo-mobile{
  position: absolute;
  top:0;
  margin-top:30px;
  left:auto;
  right:auto;
  z-index: 2;
}

</style>
