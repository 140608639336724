<template>
  <v-dialog
    :value="isDeRemaxCustomer"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title class="text-h5">
        RE/MAX Deutschland Kunde?
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <p class="text-body-1">
              RE/MAX Deutschland bietet allen Franchise-Nehmern den <strong>LeadTurbo powered by BOTTIMMO</strong> an.
              Der LeadTurbo bietet RE/MAX-spezifisches Branding und Werbemittel. Er funktioniert ansonsten
              genau wie die BOTTIMMO-App.
            </p>
            <p class="text-body-1">
              Wie sie den LeadTurbo nutzen können erfahren Sie hier: <a href="https://www.remax.de/leadturbo-info">www.remax.de/leadturbo-info</a>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-img
              :src="require('@/../public/img/remax-balloon.png')"
              contain
              height="200px"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          text
          @click="$emit('accept')"
        >
          Verstanden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    isDeRemaxCustomer: {
      type: Boolean,
      default: false
    }
  }
}
</script>
