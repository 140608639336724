<template>
  <div>
    {{ $t('register.terms-texts.0') }}
    <IubendaDocumentLink
      type="terms"
      @click.stop
    />
    {{ $t('register.terms-texts.1') }}
    <IubendaDocumentLink
      type="privacyPolicy"
      @click.stop
    />
    {{ $t('register.terms-texts.2') }}
  </div>
</template>
<script>
import brandingMixin from '@/mixins/branding'
import IubendaDocumentLink from '@/modules/iubenda/DocumentLink.vue'

export default {
  components: {
    IubendaDocumentLink
  },
  mixins: [brandingMixin]
}
</script>
